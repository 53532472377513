


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.2275990948147!2d90.38698831543141!3d23.739261895117753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b85c740f17d1%3A0xdd3daab8c90eb11f!2sIntellApps!5e0!3m2!1sen!2sbd!4v1607313671993!5m2!1sen!2sbd"></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap;