
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';


const flagSlider = [
    {
        imgUrl: 'assets/images/flag/01.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/02.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/03.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/04.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/05.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/06.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/07.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/08.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/09.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/10.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/11.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/12.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/13.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/flag/14.jpg',
        imgAlt: 'flag rajibraj91 rajibraj',
    },
]



const Sponsor = () => {
    return (
        <div className="sponsor-section section-bg">
            <div className="container">
                <div className="section-wrapper">
                    <div className="bannerflag-slider overflow-hidden">
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={2}
                            loop={'true'}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            breakpoints={{
                                0: {
                                    width: 0,
                                    slidesPerView: 3,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 8,
                                },
                                1200: {
                                    width: 1200,
                                    slidesPerView: 13.2,
                                },
                            }}
                        >
                            {flagSlider.map((val, i) => (
                                <SwiperSlide key={i}>
                                    <div className="flag-item">
                                        <div className="flag-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    {/* <div className="sponsor-slider">
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={2}
                            loop={'true'}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            breakpoints={{
                                0: {
                                    width: 0,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 3,
                                },
                                1200: {
                                    width: 1200,
                                    slidesPerView: 5.5,
                                },
                            }}
                        >
                            {sponsorList.map((val, i) => (
                                <SwiperSlide key={i}>
                                    <div className="sponsor-iten">
                                        <div className="sponsor-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
 
export default Sponsor;