import { Component, Fragment } from "react";
import FooterTwo from "../component/layout/footer-2";
import Header from "../component/layout/header";
import About from "../component/section/about";
import Banner from "../component/section/banner";
import AchievementThree from "../component/section/achievement-3";
import Event from "../component/section/event";
import Blog from "../component/section/blog";
import Course from "../component/section/course";
import Instructor from "../component/section/instructor";
import Sponsor from "../component/section/sponsor";
import Student from "../component/section/student";
import NewsLetter from "../component/section/newsletter";


const Home = () => {
    return (
        <Fragment>
            <Header />
            <Banner />
            <Sponsor />
            <About />
            <AchievementThree />
            <Event />
            <Course />
            <Instructor />
            <Student />
            <Blog />
            <NewsLetter />
            <FooterTwo />
        </Fragment>
    );
}
 
export default Home;